import React from "react";
import {FaFile, FaPencilAlt} from "react-icons/fa";

const consumableRegisterColumns = (setSelectedRow, setSelectedFilesRow, setFormModal, setFormModalFiles) => {
    return [
        {
            Header: 'Date',
            accessor: 'date',
        },
        {
            Header: 'Shift',
            accessor: 'shift',
            width: 60,
        },
        {
            Header: 'Job #',
            accessor: 'job_number',
        },
        {
            Header: 'Location',
            accessor: 'location_name',
        },
        {
            Header: 'Hole',
            accessor: 'hole_name',
        },
        {
            Header: 'Cost Code Desc',
            accessor: 'code_description',
            width: 100,
        },
        {
            Header: 'Notes',
            accessor: 'description',
            width: 350,
        },
        {
            Header: 'Charged',
            accessor: 'charged',
            width: 70,
        },
        {
            Header: 'Price',
            accessor: 'price',
            width: 80,
            Cell: (cell) => {
                const row = cell.row.original;
                return Number(row.price).toFixed(2);
            }
        },
        {
            Header: 'Markup %',
            accessor: 'markup',
            width: 60,
        },
        {
            Header: 'Total Markup',
            accessor: 'markup_price',
            Cell: (cell) => {
                const row = cell.row.original;
                return row.markup_price;
            }
        },
        {
            Header: 'Total',
            accessor: 'total',
            Cell: (cell) => {
                const row = cell.row.original;
                return row.total;
            }
        },
        {
            id: 'actions',
            Header: 'Actions',
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <>
                        <FaPencilAlt
                            className="actions"
                            title="Edit"
                            onClick={() => {
                                setSelectedRow(row);
                                setSelectedFilesRow({});
                                setFormModal({isOpen: true, action: 'edit'});
                            }}
                        />
                        <FaFile
                            className="actions ml-1"
                            title="Files"
                            onClick={() => {
                                setSelectedFilesRow(row);
                                setFormModalFiles({isOpen: true});
                            }}
                        />
                    </>
                )
            }
        }
    ]
}

export default consumableRegisterColumns;
