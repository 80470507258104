import {AccordionItem} from "react-light-accordion";
import React, {useEffect} from "react";
import {FaEraser, FaPlus, FaTrash} from "react-icons/fa";
import SearchableSelectInTable from "../../../../forms/form-control/daily/SearchableSelectInTable";
import SelectInTable from "../../../../forms/form-control/daily/SelectInTable";
import {getHours} from "../../../../../utils/listHelpers";
import InputInTable from "../../../../forms/form-control/daily/InputInTable";
import {useFieldArray} from "react-hook-form";

const DailyEmployee = (props) => {
    const {
        fieldEmployees, control, errors, register, action, daily, setValue, fieldEmployeesAvailable, jobState
    } = props;
    const {fields, append, remove} = useFieldArray({control, name: 'employee'});

    useEffect(() => {
        if (action === 'edit') {
            append(daily.daily_employee);

            setTimeout(() => {
                daily.daily_employee.forEach((item, index) => {
                    // populate name select
                    setValue(
                        `employee[${index}].user_id`,
                        {value: item.user_id, label: item.first_name + ' ' + item.last_name}
                    );
                });
            }, 200);
        }
    }, [action, append, daily, setValue]);

    const calculateTotalHoursPerProw = (e, hours, travel, totalId) => {
        e.preventDefault();
        let hoursValue = (document.getElementById(hours).value) ? document.getElementById(hours).value : 0;
        let travelHours = (document.getElementById(travel).value) ? document.getElementById(travel).value : 0;
        let totalRaw = Number(hoursValue) + Number(travelHours);
        let total = Number(totalRaw).toFixed(2);
        document.getElementById(totalId).value = total;
    }

    return (
        <AccordionItem className="card-header bg-primary" title="Employee">
            <div className="card">
                <div className="form-row">
                    <div className="col-sm-12">
                        <div className="table-responsive">
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Driller</th>
                                        <th>Hours</th>
                                        <th>Travel Hours</th>
                                        <th>Per Diem</th>
                                        <th>Total Hours</th>
                                        <th className="action-cell">
                                            <FaPlus
                                                color='#22af47'
                                                size={20}
                                                title="Add New"
                                                onClick={() => append({})}
                                            />
                                            <FaEraser
                                                color='#A51E37'
                                                className="marginLeft"
                                                size={20}
                                                title="Remove All"
                                                onClick={() => remove()}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fields.map((item, index) => {
                                        const employee = fieldEmployeesAvailable?.find(
                                            (emp)=> emp.value === item.user_id?.value
                                        );

                                        return (
                                        <tr className="employee_records" key={item.id}>
                                            <SearchableSelectInTable
                                                id={`employee[${index}].user_id`}
                                                options={(action === 'edit') ? fieldEmployeesAvailable : fieldEmployees}
                                                value={(action === 'edit') ? employee : item.user_id?.value}
                                                rules={{required: "required"}}
                                                control={control}
                                                errors={errors}
                                                customStyles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        width: 400,
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        width: 400,
                                                        position: 'sticky',
                                                    })
                                                }}
                                            />
                                            <SelectInTable
                                                className="short-select-width"
                                                id={`employee[${index}].is_driller`}
                                                value={(action === 'edit') ? item.is_driller : ''}
                                                name={`employee[${index}].is_driller`}
                                                options={[{value: true, label: 'Yes'}, {value: false, label: 'No'}]}
                                                register={register({required: "required"})}
                                                errors={errors}
                                            />
                                            { jobState === 5
                                                ?
                                                <InputInTable
                                                    className="hours-column-width"
                                                    id={`employee[${index}].hours`}
                                                    name={`employee[${index}].hours`}
                                                    value={(action === 'edit') ? item.hours : ''}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                                :
                                                <SelectInTable
                                                    className="short-select-width"
                                                    id={`employee[${index}].hours`}
                                                    name={`employee[${index}].hours`}
                                                    value={
                                                        (action === 'edit')
                                                            ?
                                                                Number(item.hours).toFixed(2)
                                                            :
                                                                ''
                                                    }
                                                    onChange={(e) => calculateTotalHoursPerProw(
                                                        e,
                                                        `employee[${index}].hours`,
                                                        `employee[${index}].travel_hours`,
                                                        `employee[${index}].total`
                                                    )}
                                                    options={getHours()}
                                                    register={register({required: "required"})}
                                                    errors={errors}
                                                />
                                            }
                                            <SelectInTable
                                                className="short-select-width"
                                                id={`employee[${index}].travel_hours`}
                                                name={`employee[${index}].travel_hours`}
                                                value={
                                                    (action === 'edit')
                                                        ?
                                                            Number(item.travel_hours).toFixed(2)
                                                        :
                                                            ''
                                                }
                                                onChange={(e) => calculateTotalHoursPerProw(
                                                    e,
                                                    `employee[${index}].hours`,
                                                    `employee[${index}].travel_hours`,
                                                    `employee[${index}].total`
                                                )}
                                                options={getHours()}
                                                register={register({required: false})}
                                            />
                                            <InputInTable
                                                className="hours-column-width"
                                                id={`employee[${index}].per_diem`}
                                                name={`employee[${index}].per_diem`}
                                                value={
                                                    (action === 'edit')
                                                        ?
                                                            item.per_diem
                                                                ?
                                                                    item.per_diem
                                                                :
                                                                    Number(0).toFixed(2)
                                                        :
                                                            ''
                                                }
                                                register={register({required: false})}
                                            />
                                            <InputInTable
                                                className="total_hours hours-column-width"
                                                id={`employee[${index}].total`}
                                                value={
                                                    (action === 'edit')
                                                        ?
                                                            (item.total > 0)
                                                                ?
                                                                    item.total
                                                                :
                                                                    Number(0).toFixed(2)
                                                        :
                                                            ''
                                                }
                                                name={`employee[${index}].total`}
                                                register={register({required: false})}
                                                disabled="true"
                                            />
                                            <td>
                                                <FaTrash
                                                    color='#A51E37'
                                                    size={20}
                                                    title="Remove"
                                                    onClick={() => remove(index)}
                                                />
                                            </td>
                                        </tr>
                                    )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </AccordionItem>
    );
}

export default React.memo(DailyEmployee);
