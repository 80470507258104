import React from 'react';
import {TableStyle} from "./clientReportRigStatusPerHoleStyle";
import TableReport from "../../../common/table/table";
import MessageEmptyData from "../../../common/messageEmptyData";
import ClientReportRigStatusTable from "./clientReportRigStatusPerHoleTable";

/**
 * @param prop
 * @returns {JSX.Element|null}
 * @constructor
 */
const ClientReportRigStatusPerHoleBody = (prop) => {
    const {filters, tableData} = prop;

    if (!filters.isSubmitted) {
        return null;
    }

    if (tableData.length === 0) {
        return <MessageEmptyData/>;
    }

    return (
        <div className="container-fluid">
            <TableStyle>
                <TableReport
                    columns={ClientReportRigStatusTable()}
                    data={tableData}
                    exportOptions={{pdf: false, xlsx: true, csv: true}}
                    getExportFileName={() => 'rig_status'}
                    scrollable={true}
                    withGlobalSearch={true}
                    fixedTotalRow={true}
                />
            </TableStyle>
        </div>
    );
}

export default ClientReportRigStatusPerHoleBody;
